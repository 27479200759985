.image {
  /* width: 100%; */
  height: 70%;
  object-fit: cover;
  /* padding: 0 1rem 1rem 1rem; */
  cursor: pointer;
}
.cardItem {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* height: 100%;
  margin: 1rem; */

  position: relative;
  justify-content: flex-end;

  min-width: 200px;
}
.cardItem > img {
  filter: grayscale(100%);
}
.cardItem > img:hover {
  filter: brightness(125%);
}
.cardTitleContainer {
  visibility: hidden;
  font-family: 'open sans';
  color: #B6B6B6;
  text-transform: uppercase;
  font-size: 14px;

  position: absolute;
  top: 50px;

  width: 100%;
}
.cardTitleContainer p {
  margin: 0;
}
.cardTitle {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.hoveredCardTitleContainer {
  animation: hoveredCardTitleContainer 250ms ease-in-out;
  visibility: visible;
}
@keyframes hoveredCardTitleContainer {
  0% {
    transform: translateY(50%);
    visibility: hidden;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
  }
}
.clientTitle {
  font-weight: 600;
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .cardTitleContainer {
    visibility: visible;
    top: -80px;
  }
  .cardItem > img {
    filter: grayscale(0%);
  }
}
@media (max-width: 900px) {
  .cardTitleContainer {
    visibility: visible;
    top: -80px;
  }
  .cardItem > img {
    filter: grayscale(0%);
    /* width: 350px;
    margin: 0 auto; */
  }
}
