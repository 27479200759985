.amount {
  font-weight: 900;
  font-size: 3.5rem;
}

.description {
  font-weight: 500;
  font-family: open sans;
}




/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .amount {
    font-size: 2rem !important;
  }
  .description {
    /* max-width: 80%; */
    font-size: 0.8rem;
  }
}


