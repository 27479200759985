/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,300;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,700;1,300;1,400&display=swap');

@font-face {
  font-family: 'HussarBold';
  src: local('HussardBold'), url(./assets/fonts/HussarBold.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'HussarBoldOutline';
  src: local('HussarBoldOutline'), url(./assets/fonts/HussarBoldOutline.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'Champagne';
  src: local('Champagne'), url(./assets/fonts/Champagne.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CopperplateRegular';
  src: local('CopperplateRegular'), url(./assets/fonts/CopperplateRegular.ttf) format('truetype');
  font-display: swap
}
/* // Body */
.f9w4 {
  margin: 0;
  font-family: hussarbold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}
