.titleContainer {
  height: 100px;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  font-family: HussarBold;
}

.title {
  color: white;
  font-size: 54px;
  text-align: center;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
}

.image {
  display: inline-block;
  vertical-align: middle;
  height: 70px;
  margin-left: 50px;
  animation: pulse 650ms infinite alternate;
  filter: brightness(100%);
}
.image:hover {
}
@keyframes pulse {
  0% {
    filter: brightness(50%);
  }
  100% {
    filter: brightness(100%);
  }
}

.span {
  font-family: HussarBoldOutline;
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .titleContainer {
    margin-top: 0rem;
  }
  .title {
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .image {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
    max-height: 10vh;
  }
}