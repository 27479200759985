.HeroVideoContainer {
  height: 100vh;
  position: relative;
}
.ytp-button {
  display: none !important;
}
/* .videoElementContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.videoElementContainer video {
  object-fit: cover;
} */
.videoElement {
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
}
.playerControls {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  flex-direction: column;
  color: white;
}
.buttonPlayPause {
  background: transparent;
  outline: none;
  border: none;
  color: rgba(255, 255, 255, 0.1);
  max-width: 2rem;


  display: flex;
  justify-content: center;
  font-size: 2rem;
  width: max-content;
  margin: 0 auto;
  color: white;
  cursor: pointer;
}
.buttonPlayPause:hover {
  animation: grow 200ms ease-in;
  font-size: 3.5rem;
}

@keyframes grow {
  0%{font-size: 3rem;}
  100%{font-size: 3.5rem;}
}
.buttonPlayPause span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.buttonPlayPause:focus {
  outline: none;
}

.fullScreenButtonsContainer {
  font-size: 2rem;
  /* background: gray; */
  color: white;
}
.buttonsUpperSection {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.lowerButtonsText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: hussarboldoutline;
  color: white;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 1rem;
}
.playPauseInvisibleButton {
  /* background: rgba(218, 3, 3, 0.5); */
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50%;
  height: 50%;
}
.playPausePop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50%;
  height: 50%;
  font-size: 8rem;
  opacity: 0;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5);
}

.lowerButtonsWrapper {
  display: flex;
  justify-content: space-between;
}
.buttonsLowerSection {
  margin: 0 1rem;
  width: 95%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  padding: 0.7rem;
}
.lowerButtonsLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoTimer {
  display: flex;
  justify-content: center;
}
.videoTimerString {
  margin: 0 1rem;
  font-family: hussarbold;
  font-size: 1rem;
  color: white;
  user-select: none;
}
.lowerButtonsRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonFullScreen {
  font-size: 3rem;
  color: white;
  background: transparent;
  border: none;
}
.buttonFullScreen > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.videoProgress {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.2);
  height: 25px;
  position: relative;
  margin: 0.5rem auto;
  cursor: pointer;
}
.videoProgressDot {
  width: 25px;
  height: 25px;
  background: white;
  border: solid 3px black;
  border-radius: 50%;
  position: absolute;
  /* left: 0%; */
}
.videoProgressFilled {
  background: white;
  height: 100%;
}
.volumeIconContainer {
  font-size: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 2rem;
}
.volumeIconContainer > svg:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
}
.volume {
  background: transparent;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .HeroVideoContainer {
    height: 90vh;
  }
  .playerControls {
    overflow: hidden;
  }
  .volumeIconContainer {
    margin: 1rem 1rem 1rem 0;
  }
  .volume {
    width: 50%;
  }
  .videoTimerString {
    margin: 0;
    width: 100%;
  }
  .lowerButtonsLeft {
    justify-content: space-between;
    width: 100%;
  }
  .buttonPlayPause {
    margin: 0;

  }
}
