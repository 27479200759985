.pageContainer {
  width: 100%;
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url('../../assets/background/background_members.jpg');
  background-size: cover;
  background-attachment: fixed;
  padding: 100px 20px 0px 20px
}

.footerContainer {
  width: 100%;
  margin-top: 100px;
}
/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .pageContainer {
    padding: 5rem 0 2rem 0;
  }
  .pageContainer > div:nth-child(2) {
    margin-top: 2rem;
  }
}
