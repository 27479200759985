.scrollContainer {
  height: 200vh;
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/background/compteur-background-2.jpg);
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.sloganTop {
  font-family: HussarBoldOutline;
  color: white;
  margin: 100px;
  height: 100px;
  white-space: nowrap;
  font-size: 4rem;
  text-transform: uppercase;
  z-index: 35;
}
.compteurContainerDesktop {
  color: white;
  width: 65%;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* position: absolute; */
  left: 0;
  right: 0;
  /* padding-top: 7rem; */
  font-family: hussarbold;
  font-size: 1.15rem;
}
.startCounter {
  visibility: visible;
}
.compteurTile {
  width: 40%;
  margin: 1rem;
}
.compteurTile > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1500px) {
  .sloganTop {
    font-family: HussarBoldOutline;
    color: white;
    margin: 100px;
    height: 100px;
    white-space: nowrap;
    font-size: 3rem;
    text-transform: uppercase;
    z-index: 35;
  }
}
/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .compteurContainerMobile {
    width: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .scrollContainer {
    background:linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)), url(../../assets/background/compteur-background-mobile.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-attachment: fixed;
  }
  .compteurMobileTile {
    width: 30%;
    margin: 0;
    justify-content: center;
  }
  .compteurMobileTile > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobileTileRow {
    display: flex;
    color: white;
    justify-content: space-evenly;
    margin: 1rem 0px;
  }
}

