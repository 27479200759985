.expertiseWrapper {
  height: 200vh;
  position: relative;
  width: 100%;
  background: url(../../assets/background/SOUS_COUCHE_V2.png);
}
.expertiseContainer {
  height: 100vh;
  z-index: 2;
  /* position: relative; */
  position: absolute;
  overflow: hidden;
  /* background: black; */
  background-size: 100%;
  width: 100%;
  transform: scale(1);
  user-select: none;
}
.pageTitle {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  position: absolute;
  left: 10px;
  font-family: HussarBold;
  font-size: 3rem;
  z-index: 2;
  top: 2%;
  color: white;
}
.surcoucheContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .surcouche {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.surcoucheBg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: url(../../assets/background/SURCOUCHE_V3.png);
  /* background-size: 100%; */
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.tabContainer {
  position: relative;
  height: 75vh;
  z-index: 15;
  font-family: HussarBoldOutline;
  color: #f4f4f4;
  font-size: 1.8rem;
  text-transform: uppercase;
}
.tabsTopRow, .tabsBottomRow {
  display: flex;
  justify-content: space-around;
  height: 25vh;
  width: 100%;
}
.tabsTopRow {
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.tabsBottomRow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 14vh;
}
/* .tabsTopRow > div:nth-child(2) > p{
  margin-bottom: 3rem;
}
.tabsBottomRow > div:nth-child(2) > p {
  margin-top: 3rem;
} */
.tabsMidRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.tabsMidRow > p {
  margin: 0;
  height: 100px;
  font-size: 2.2rem;
  cursor: pointer;
}
.span {
  font-family: HussarBoldOutline;
}

.tab {
  z-index: 2;
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.tab > p {
  /* width: max-content; */
  cursor: pointer;
  margin: 0;
  position: absolute;
}
.tab > p:hover {
  font-family: HussarBold;
}

.activeTab {
  font-family: HussarBold;
}

.agence {
  justify-content: flex-end;
  text-align: right;
  align-items: flex-end;
}
.partenariats > p {
  width: max-content;
}
.partenariatsEtSponsoring {
  color: white;
  text-align: center;
}
.expert {
  justify-content: flex-end;
  text-align: left;
  align-items: flex-start;
}
.evenementiel {
  justify-content: flex-start;
  align-items: flex-end;
}
.audiovisuel {
  justify-content: flex-end;
  text-align: center;
}


.notActiveTab  {
  border-bottom: 1px solid;
  animation: border-pulsate 3s infinite;
}

@keyframes border-pulsate {
    0%   { border-color: rgb(133, 134, 134); }
    50%  { border-color: rgba(2, 53, 53, 0); }
    100% { border-color: rgba(133, 134, 134, 0); }
}

@media (max-width: 1700px) {
  .audiovisuel {
    justify-content: center;
  }
}
.conception {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}






.sliderContainer {
  position: absolute;
  bottom: 0;
  height: max-content;
  width: 100%;
}
.slider {
  position: relative;
  height: max-content;
  width: 100%;
  height: 25vh;
  margin-bottom: 1rem;
}
.cardContainer {
  width: 85vw;
  height: max-content;
  position: absolute;
  bottom: 0;
  z-index: 35;
  white-space: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow-y: hidden;
  align-items: flex-end;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.cardContainer::-webkit-scrollbar {
  display: none;
}
.cardContainer > div {
  margin: 1rem 1rem 0 1rem;
}
.arrowsContainer {
  width: 97vw;
  position: absolute;
  height: 100%;
  z-index: 45;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  color: white;
  pointer-events: none;
}
.arrow {
  font-size: 6rem;
  position: absolute;
  cursor: pointer;
  pointer-events: initial;
  height: 115%;
}
.arrowForward {
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(21, 21, 21, 0.5);
  width: max-content;
}
.arrowForwardIcon,
.arrowBackIcon {
  user-select: none;
}
.arrowForwardIcon {
  width: 3rem;
  margin: 2rem;
}
.arrowBack {
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(21, 21, 21, 0.5);
  width: max-content;
  visibility: hidden;
}
.arrowBackIcon {
  width: 3rem;
  margin: 2rem;
  transform: rotate(180deg);
}
.arrowBackFadeOut, .arrowForwardFadeOut {
  animation: arrowFadeOut linear 400ms;
}
@keyframes arrowFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .expertiseWrapper {
    height: 100vh;
  }
  .tabContainer {
    font-size: 1.1rem;
  }
  .surcoucheBg {
    background-size: cover !important;
  }
  .pageTitle {
    writing-mode: inherit;
    transform: rotate(0deg);
    top: 2%;
    left: 0;
    right: 0;
    font-size: 1.8rem;
    margin: 0 auto;
  }
  .tabsTopRow, .tabsBottomRow {
    width: 80%;
  }
  .agence {align-items: flex-start; text-align: left; width: 100%;}
  .partenariats {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .expert {align-items: flex-end; text-align: right; width: 100%;}

  .cardContainer > div {
    margin: 1rem 1.5rem 0 1.5rem;
  }
}


/* # # # # # # # # # # # #
MOBILE EXPERTISE
# # # # # # # # # # # #  */
.mobileExpertiseWrapper {
  height: 100vh !important;
}
.expertiseMobileContainer {
  height: 100vh;
  background: url(../../assets/background/SOUS_COUCHE_V2.png);
  width: 100%;
  user-select: none;
}
.mobilePageTitle {
  font-family: HussarBold;
  font-size: 1.8rem;
  color: white;
}
.pageTitleMobile {
  padding: 0.5rem;
  margin: 0;
  max-height: 10vh;
}
.mobileSpan {
  font-family: HussarBoldOutline;
}

.mobileSlider {
  position: relative;
  top: 0;
  width: 100%;
  height: 90vh;
}
.mobileFilters{
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 5vh;
  z-index: 250;
  /* background: rgba(255, 255, 255, 0.2); */
}
.mobileOpenFiltersButton {
  width: 100%;
  margin: 0 auto;
  font-size: 1.5rem;
  /* background: #151515; */
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
}
.mobileOpenFiltersButton > svg {
  color: white;
  font-size: 2.5rem;
}
.filtersVeil {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 220;
  touch-action: none;
}
.mobileFilterButtonTitle {
  color: white;
  font-size: 1rem;
  margin: 1rem 0.2rem 1rem 1rem;
  font-family: hussarbold;
}
.mobileFilterButtonArrowDown {
  font-size: 1rem;
  color: white;
  margin: 1rem 0;
}
.mobileFilterButtonTitleContainer {
  display: flex;
  justify-content: flex-start;
  width: 80%;
}
.closeFiltersButton {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-size: 1rem;
}
.closeFiltersButtonIcon {
  color: white;
}
.closeFiltersButtonIcon > svg {
  font-size: 2rem;
}
.closeFiltersButtonIcon > svg > path {
  stroke: white;
}


.mobileFiltersContent {
  height: max-content;
  background: #151515;
  /* padding: 1rem; */
}
.filtersSections {
  /* height: max-content; */
  /* height: 0vh; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: hidden;
  /* max-height: 70vh; */
}
.filtersMobileTop {
  height: max-content;
  margin: 1rem;
}
.filtersMobileBottom {
  height: 100%;
  margin: 1rem;
}
.bottomLine {
  display: block;
  margin: 0 auto;
  border-bottom: solid white 2px;
}
.lightBackground {
  background: #1b1b1b;
}
.mobileFilterTabTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: hussarbold;
  color: #5a5a5a;
  text-transform: uppercase;
}
.mobileFilterTabTitle {
  margin: 0.5rem;

}
.mobileFilterRow {
  display: flex;
  flex-direction: column;
  font-family: hussarbold;
  width: 100%;
}
.mobileFilterTabLine {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  border: 1px dashed #5a5a5a;
  align-items: center;
}
.activeMobileTabLine {
  border: 1px solid white;
}
.mobileFilterTabHalfLine {

}
.mobileFilterCheckBoxContainer {

}
.mobileFilterCheckBox {
  color: #5a5a5a;
  margin: 0 1rem 0 0.5rem;
}
.mobileFilterTabContainer {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem;
}
.mobileFilterTab {
  width: 100%;
  margin: 0;
  color: #5a5a5a;
  font-size: 0.9rem;
}
.activeMobileTab, .activeMobileCheckBox {
  color: white
}

/* //  */

/* //  */
.mobileEvents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
  overflow-x: scroll;
}
.mobileEventsTopRow, .mobileEventsBottomRow {
  display: flex;
  justify-content: flex-start;
}
.mobileEventsBottomRow {
  /* margin-left: 6rem; */
}
.mobileThumbnail {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobileThumbnail > div {
  width: 75vw !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mobileThumbnail > div > img {
  filter: none !important;
}
