.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/background/contactpage.jpg');
  background-position: center;
  background-size: cover;
}

.text {
  color: antiquewhite;
  font-family: 'HussarBold';
}

.link {
  font-size: 24px;
}
