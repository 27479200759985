.cardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 3rem;
}
.image {
  height: 100%;
  object-fit: cover;
  /* width: 250px; */
  border-radius: 100%;
  border: gray 1.4px dotted;
}

.position {
  color: white;
  font-size: 30px;
}
.descriptionForMemberspage {
  color: white;
  font-size: 22px;
  font-weight: 100;
}
.descriptionForHomepage {
  font-size: 16px;
  font-weight: 100;
  font-family: hussarbold;
  color: white;
}

.infoContainer {
  width: 100%;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .cardContainer {
    margin: 1rem;
    width: 40%;
  }
  .image {
    width: 100px !important;
    height: 100px !important;
  }
  .descriptionForMemberspageMobile {
    font-size: 16px;
    color: white;
  }
  .positionMobile {
    font-family: "open sans";
    font-style: italic;
    color: grey;
  }
}