
/* .fwc {
  width: 100%;
} */
.navbarContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  z-index: 28;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 10%;
  height: 90px;
}

.logo,
.logo img {
  width: 20px;
  cursor: pointer;
  object-fit: contain;
}

.link {
  text-decoration: none;
  color: #cacaca;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 14%;
  text-transform: uppercase;
  font-family: 'open sans';
  font-size: 1.1rem;
  font-weight: 600;
  height: 100%;
  position: relative;
}
.link img {
  width: 28px;
}
.link p {
  margin: auto;
}
.link:after{
  content: "";
  position: absolute;
  background-color: white;
  height: 1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  width: 0;
  transition: 0.3s;
}
.link:hover:after{
  width: 80%;
}
.link:hover {
  color: white;
  text-decoration: none;
}
.link:active {
  transform: scale(1.1);
}
.active {
  color: white;
}
.active:after{
  content: "";
  position: absolute;
  background-color: white;
  height: 1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  width: 80%;
}

/* body {
  animation: preventScrollDuringTransition 1000ms linear;
}
@keyframes preventScrollDuringTransition {
  0% {
    overflow: hidden;
  }
  90% {
    overflow: hidden;
  }
  100% {
    overflow: initial;
  }
} */

/* Elements that need to be hidden in desktop */
.mobileNav {
  display: none;
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .mobileNav {
    display: block;
    position: relative;
    z-index: 260;
  }
  .stickyMobileNavButton {
    position: fixed !important;
  }
  .navbarContainer {
    display: none;
  }
  .mobileNavbarButton {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 3rem;
    color: white;
    margin: 30px;
    z-index: 120;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .navbarMobileContainer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(21, 21, 21, 0.9);
    width: 100%;
    padding-bottom: 2rem;
    transform: translateX(-100%);
    opacity: 0;
    height: 100vh;
  }
  .mobileNavLogoContainer {
    width: 100%;
  }
  .mobileNavLogo {
    width: 10vw;
    margin: 2rem;
  }
  .mobileNavLink {
    width: 100%;
    padding: 1rem;
    text-transform: uppercase;
  }
  .navElement {
    color: white;
    text-decoration: none;
    font-family: Hussarbold;
    margin: 0.6rem;
  }
  .active {background: white;}
  .active > p {color: #151515;}
}