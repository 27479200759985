.backgroundVideo {
  position: absolute;
  width: 100%;
}

.contactContainer {
  height: 100vh;
  /* height: 100%; */
  /* height: calc(100vh - 130px); */
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  padding: 100px 20px 2rem 20px;
  /* margin-top: 130px; */


  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContactUsIconsContainer {
  display: flex;
  justify-content: center;
  margin: 4rem;
}
.ContactUsIcons {
  max-width: fit-content;
  max-height: fit-content;
  border: white 2px solid;
  border-radius: 50%;
  margin: 1rem;
  overflow: hidden;
}
.ContactUsIcons svg {
  color: white;
  font-size: 5rem;
  padding: 1rem;
}
.ContactUsIcons svg:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.titleContainer {
  height: 100px;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}
.title {
  color: white;
  font-size: 54px;
  text-align: center;
  width: auto;
  margin: 0 auto;
}

.image {
  display: inline-block;
  vertical-align: middle;
  height: 70px;
  margin-left: 50px;
}
.span {
  font-family: HussarBoldOutline;
}
.contactUsBackground {
  display: block;
}
.contactUsBackground > img {
  filter: brightness(0.3) !important;
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .ContactUsIcons {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
  }
  .ContactUsIconsContainer {
    margin: 0rem;
  }
  .contactContainer {
    padding: 1rem;
    margin-top: 6rem;
  }
  .titleContainer {
    height: max-content;
  }
}
