/* #partenairesContainer {
  height: 300vh;
  width: 100%;
} */
.scrollContainer {
  height: 200vh;
  position: relative;
}
.partenairesContainer {
  width: 100%;
  height: 100%;
  /* background: #151515; */
  overflow: hidden;
  position: relative;
  /* background: rgb(40,40,40); */
}
.cardBoard {
  height: 90vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 25%;
  height: 15%;
}
.cardContainer > img {
  filter: grayscale(1);
}
.cardContainer > img:hover {
  animation: colorTheCard 300ms linear;
  filter: grayscale(0);
  cursor: pointer;
  transform: scale(1.05);
}
@keyframes colorTheCard {
  0% {
    filter: grayscale(1);
    transform: scale(1);
  }
  100% {
    filter: grayscale(0);
    transform: scale(1.05);
  }
}
.partenaireImage {
  width: 215px;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.gridContainer {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: 100vh;
}
.gridContainerSticky {
  position: fixed;
  top: 0;
  left: 0;
}
.title {
  font-family: HussarBoldOutline;
  color: white;
  font-size: 60px;
}

.span {
  font-family: HussarBold;
  color: white;
}

.haloImgContainer {
  position: absolute;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: none;
}
.haloImg {
  position: absolute;
  bottom: -50%;
  left: -10%;
  width: 100%;
  height: max-content;
  object-fit: contain;
  transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%;
}


/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .scrollContainer {
    height: 100vh;
  }
  .title {
    font-size: 1.8rem;
  }
  .cardBoard {
    padding: 1rem;
  }
  .partenaireImage {
    width: 100%;
    height: 100%;
  }
  .haloImg {
    bottom: 0;
    width: 250vw;
  }
  .squadRow {
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {

  .title {
    font-size: 1.5rem;
  }

}
