/* // Html */
.c54v {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* // Body */
.f9w4 {
  /* animation: preventScrollDuringVideoIntro 4800ms linear; */
  height: 100%;
}

.backgroundPartenairesMembers {
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/background/Background_tout_en_un.png');
  background-position: 50% 50%;
  background-size: cover;
}
.homeContainer {
  background: #151515;
}
#topAnchor {
  display: block;
  width: 0;
  height: 0;
}
@keyframes preventScrollDuringVideoIntro {
  0% {
    overflow: hidden;
  }
  90% {
    overflow: hidden;
  }
  100% {
    overflow: auto;
  }
}
