.pageContainer {
  position: relative;
  width: 100%;
  background-size: cover;
}

.background1 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),    url('../../assets/background/background_agence_1.jpg');
  background-size: cover;
}

.topBannerContainer {
  padding-top: 10rem;
  width: 100%;
  height: 100%;
}
.topBanner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mosaique, .mosaiqueMobile {
  width: 100%;
}
.background2 {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('../../assets/background/background_agence_2.jpg');
  background-position: center;
  background-size: cover;
}
.pageTitle {
  margin-left: 10px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  position: fixed;
  top: 10%;
  left: 0;
  font-family: HussarBold;
  font-size: 3rem;
  z-index: 2;
  color: #f4f4f4;
}

.introduction {
  font-family: HussarBoldOutline;
  font-size: 30px;
  color: #f4f4f4;
  width: 80%;
  text-align: center;
  margin-left: 10%;
}

.introductionSpan {
  font-family: HussarBold;
}

.bigText {
  font-family: HussarBold;
  color: white;
  opacity: 0.3;
  font-size: 8rem;
  overflow-x: hidden;
  white-space: nowrap;
  padding-top: 8rem;
}



.whoAreWeTitle,
.ourServicesTitle,
.theyTrustUsTitles,
.successfulStoryTitle {
  display: block;
}
.successfulStoryTitle {
  font-size: 8rem;
}
.servicesContainerParent {
  width: 100%;
  height: 100%;
  position: relative;
}
.servicesContainerDesktop {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
}
.service {
  position: relative;
  width: 272px;
  width: 20%;
}
.serviceImage {
  height: 452px;
  width: 100%;
  object-fit: cover;
  -webkit-filter: grayscale(1) brightness(50%);
}

.service:hover .serviceImage {
  -webkit-filter: brightness(40%) grayscale(0);
}

.serviceText {
  color: white;
  font-family: HussarBold;
  font-size: 30px;
  /* writing-mode: tb-rl; */
  /* transform: rotate(-180deg); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.serviceText:hover {
  /* transform: rotate(90deg); */
  /* transition: transform 1s; */
}

.serviceDescription {
  width: 242px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  text-shadow: 2px 4px 2px rgba(0, 0, 0, 0.35);
  font-family: 'open sans';
  margin: 1rem auto;
  animation: serviceDescriptionAppears 300ms ease-in-out;
}
@keyframes serviceDescriptionAppears {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.serviceTextSpan {
  font-family: HussarBoldOutline;
  font-size: 30px;
}

.talentsContainerParent {
  width: 90%;
  height: 100%;
  margin: 150px auto;
  margin-bottom: 50px;
  position: relative;
}
.talentsContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: auto;
}

.bottomBanner {
  height: 320px;
  width: 880px;
  object-fit: cover;
}

.bottomBannerContainer {
  position: relative;
  height: 295px;
  width: 885px;
  margin: 0 auto;
  font-size: 31px;
  color: white;
}

.bannerTopText {
  position: absolute;
  top: -30px;
  left: -100px;
  font-family: HussarBoldOutline;
}
.bannerBottomText {
  position: absolute;
  font-family: HussarBold;
  bottom: -25px;
  right: -100px;
}

.catalogueContainer {
  height: 150px;
  background: grey;
  width: 400px;
  margin: 0 auto;
}

.catalogueText {
  opacity: 1;
  font-size: 24px;

}
.catalogueDownloadButton {
  background: black;
  padding: 1rem;
  font-size: 14px;
  font-family: hussarbold;
}
.catalogueDownloadButton:hover {
  animation: catalogueCTA 2s;
}
@keyframes catalogueCTA {
  from {
    border: 0px white solid;
  }
  to {
    border: 2px white solid;
  }
}
.catalogueDownloadButtonText {
  margin: 0;
}

.downloadIcon {
  width: 60px;
  color: white;
  margin-bottom: 50px;
}
/* / / / / / / / / / */
/* STYLE FOR DOWNLOAD BUTTON */
/* / / / / / / / / / */

.downloadButtonP {
  height: 2rem;
}
.buttonElement {
  position: absolute;
  display: block;
  width: 230px;
  height: 42px;
  color: #ffffff;
  font-size: 14px;
  line-height: 200%;
  text-decoration: none;
  text-align: center;
  z-index: 1;
  font-family: hussarbold;
}
.buttonElement span {
  position: absolute;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  z-index: 1;
}
.buttonElement .borderDLButton {
  border: 1px solid #ffffff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.buttonElement .topDLButton, .buttonElement .bottomDLButton, .buttonElement .leftDLButton, .buttonElement .rightDLButton {
  background-color: rgb(255, 255, 255);
  height: 1px;
  width: 0;
}
.buttonElement .leftDLButton, .buttonElement .rightDLButton {
  height: 0;
  width: 1px;
}
.buttonElement .topDLButton {
  top: -5px;
  left: -15px;
}
.buttonElement .bottomDLButton {
  bottom: -5px;
  right: -15px;
}
.buttonElement .leftDLButton {
  top: -15px;
  right: -5px;
}
.buttonElement .rightDLButton {
  bottom: -15px;
  left: -5px;
}
.buttonElement:hover {
  color: #fff;
}
.buttonElement:hover .borderDLButton {
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
}
.buttonElement:hover .topDLButton, .buttonElement:hover .bottomDLButton {
  width: 230px;
}
.buttonElement:hover .leftDLButton, .buttonElement:hover .rightDLButton {
  height: 40px;
}
.buttonElement:hover .topDLButton {
  left: -5px;
}
.buttonElement:hover .bottomDLButton {
  right: -5px;
}
.buttonElement:hover .leftDLButton {
  top: -5px;
}
.buttonElement:hover .rightDLButton {
  bottom: -5px;
}
.buttonElement .textDLButton {
  position: relative;
  z-index: 1;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
}






/* / / / / / / / / / */
/* STYLE FOR MOBILE SERVICES */
/* / / / / / / / / / */
.servicesContainerMobile {
  color: white;
}
.servicesContainerMobile > div:nth-child(even) {
  flex-direction: row-reverse;
  padding: 2rem 1rem 2rem 0;
}
.servicesContainerMobile > div:nth-child(even) > .serviceImageMobile {
}
.servicesContainerMobile > div:nth-child(even) > .serviceTextMobile {
}
.servicesContainerMobile > div:nth-child(even) > .serviceTextMobile > .serviceTextSpanContainerMobile
{
  transform: translateX(15%);
  text-align: right;
}
.servicesContainerMobile > div:nth-child(even) > .serviceDescriptionMobile {
  text-align: right;
}
.serviceMobile {
  display: flex;
  height: 100%;
  padding: 2rem 0 2rem 1rem;
}
.servicesContainerMobile {
  height: 100%;
  overflow: hidden;
}
.serviceImageMobile {
  object-fit: cover;
  width: 17vh;
  height: 35vh;
}
.serviceTextMobile {
  font-family: "open sans";
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serviceTextSpanContainerMobile {
  font-family: hussarbold;
  font-size: 1.5rem;
  text-align: left;
  transform: translateX(-15%);
}
.serviceDescriptionMobile {
  text-align: left;
  margin: 0 1rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.serviceTalentDownloadButtonContainer {
  width: 100%;
  margin: 0.5rem;
}
.serviceTalentDownloadButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  text-decoration: none;
  color: white;

  width: max-content;
  background: blue;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
}
.serviceTalentDownloadButton > svg {
  margin: 0 0.5rem;
}
.catalogueLinkMobile {
  text-decoration: none;
  margin: 0;
}

@media (max-width: 1750px) {
  .bigText {
    font-size: 7rem;
  }
}

@media (max-width: 1650px) {
  .bigText {
    font-size: 6rem;
  }
}
@media (max-width: 1400px) {
  .bigText {
    font-size: 5rem;
  }
}

@media (max-width: 500px) {
  .bigText {
    font-size: 1.5rem !important;
  }

  .whoAreWeTitle {
    /* font-size: 1.3rem !important; */
  }
}
/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .pageContainer {
    overflow-x: hidden;
    height: max-content;
    width: 100vw;

  }
  .pageTitle {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    margin: 0 auto;
    font-size: 2rem;
    position: absolute;
    right: 0;
    padding-top: 6rem;
  }

  .introduction {
    font-size: 1rem;
  }
  .background1 {
    /* padding-top: 5rem; */
  }
  .bigText {
    font-size: 2.5rem;
    padding-top: 5rem;
  }
  .catalogueContainer {
    max-width: 100%;
  }
  .talentsContainer {
    flex-direction: column;
  }
  .talentsContainer {
    padding: 0 1rem 0 1rem;
  }
  /* cardContainer */
  .talentsContainer > div {
    width: 65vw;
    margin: 1rem 0;
  }
  .talentsContainer > div:nth-child(2) {
    margin-left: auto;
  }
  .talentsContainer > div > img {
    width: 40vw;
    height: 40vw;
  }

  /* nickname */
  .talentsContainer > div > div > p:nth-child(1) {
    font-size: 1rem;
  }
  /* description */
  .talentsContainer > div > div > p:nth-child(2) {
    font-size: 0.8rem;
  }
  /* gameTeam */
  .talentsContainer > div > div > p:nth-child(2) {
  }
  /* // */
  .bottomBannerContainer {
    font-size: 1rem;
    margin-top: 4rem;
    max-width: 100vw;
  }
  .bottomBanner {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .successfulStoryTitle {
    padding-top: 4rem;
  }
  .bannerTopText {
    top: -15px;
    left: 20px;
  }
  .catalogueContainer {
    margin: 4rem auto;
    background: transparent;
    padding: 1rem;
  }
  .catalogueText {
    font-size: 1.5rem;
    font-family: hussarbold;
    color: white;
  }
  .catalogueButton {
    font-family: 'open sans';
    background: transparent;
    border: none;
    text-decoration: underline;
  }
  .catalogueButton > a {
    color: white;
    text-decoration: underline;
  }
}

.background3 {
  height: 130vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.showreelContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.footerContainer {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.plateauBleu {
  background: no-repeat, linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/background/vision-plateau-bleu.jpg');
  background-position: 50% 50%;
  background-size: cover;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 130vh;
  overflow: hidden;
}

.plateauBleuLabel {
  color: white;
  font-size: 1.5rem;
  width: 100%;
  z-index: 25;
  text-align-last: right;
  position: absolute;
  bottom: 0;
  padding: 2rem;
}

.plateauBleuLabel p {
  margin: 0;
  text-transform: uppercase;
  font-family: hussarboldoutline;
}

.plateauBleuLabel p > span {
  font-family: hussarbold;
}


.plateauBleuHeaders {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: hussarbold;
  color: white;
  align-items: center;
  font-size: 1.5rem;
  width: 100%;
  height: 95vh;
}

.pbhLeft, .pbhMid, .pbhRight, .pbhTop {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pbhTop {
  justify-content: flex-start;
  font-size: 4rem;
}
.pbhLeft {
  justify-content: flex-end;
}
.pbhMid {
  justify-content: center;
}
.pbhRight {
  justify-content: flex-start;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .titleContainer {
    top: 53%;
    left: -50%;
  }
  .titleContainer > * {
    font-size: 2rem;
  }

  .topImagesContainer {
    /* height: 110vh; */
    height: 100%;
    margin: 1rem;
  }

  .image1 {
    top: 0;
    left: 20%;
    right: 0;
  }
  .image2 {
    bottom: 0;
    left: -100%;
    right: 0;
  }
  .image1, .image2 {
    margin: 0 auto;
    border: gray 2px solid;
    max-height: 50vh;
    width: 130vw;
  }
  /* //SHOWREEL */
  .plateauBleuHeaders {
    font-size: 0.8rem;
    height: 94vh;
    width: 94vw;

  }
  .pbhLeft {
    text-align: left;
    width: 33%;
  }
  .pbhMid {
    text-align: center;
    width: 33%;
  }
  .pbhRight {
    text-align: right;
    width: 33%;
  }

  .pbhTop {
    font-size: 35px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plateauBleuLabel {
    font-size: 1rem;
    top: 50%;
    bottom: "";
    text-align: right;
  }


}
