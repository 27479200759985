.footerContainer {
  padding: 1rem;
  display: block;
}
.footerContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.logo {
  width: 100px;
}

.link a {
  color: white !important;
}
.link span {
  font-size: 1.5rem;
}

a:hover {
  text-decoration: none;
  color: white;
}

.link {
  text-align: left;
}

/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .footerContainer > div {
    margin: 0 auto;
  }
}