.pageContainer {
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  url('../../assets/background/mentions-bg.png');
  background-position: center;
  background-size: cover;
}

.contentContainer {
  width: 100%;
  padding: 200px 100px;
  color: white;
  min-height: 100vh;
  text-align: left;
}

.center {
  width: fit-content;
  margin: 0 auto;
  border-bottom: 1px solid white;
  font-size: 40px;
}

.title {
  width: fit-content;
  margin-top: 30px;
  border-bottom: 1px solid white;
}
