.cardContainer {
  width: 100%;
}
.cardContainer:nth-child(1) {
  margin-top: 10%;
}
.cardContainer:nth-child(2) {
  margin-top: 0;
}
.cardContainer:nth-child(3) {
  margin-top: -10%;
}
.image {
  cursor: pointer;
  width: 500px;
  height: 400px;
  border-radius: 10px;
}

.nickname {
  font-family: 'Open Sans';
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
}

.description,
.gameTeam {
  font-family: 'Open Sans';
  font-weight: 500;
  color: white;
  font-size: 18px;
}
.gameTeam {
  font-style: italic;
}

.cardContent {
  margin-top: 15px;
}
/* # # # # # # # # # # # */
@media (max-width: 1200px) {
  .cardContent {
    font-size: 1rem;
  }
}
